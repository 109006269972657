import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';
import { useGetAccountAppMutation, useGetObjectsMutation } from '../../../../Store/api/apps';
import { useGetCountTransactionsByClientMutation } from '../../../../Store/api/count';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';
import { formatDate } from '../../../../Utils/times';

export const ObjectView = ({account, generalApp}) => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  let [searchObjectFetch, { isLoading: isLoadingGetObjects, isSuccess: isSuccessObjects, data: responseObjects }] = useGetObjectsMutation();
  let [searchTransactions, { isLoading: isLoadingTransactions, isSuccess: isSuccessTransaction, data: responseTransactions }] = useGetCountTransactionsByClientMutation();

  let navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return <>
              <LinkStyles onClick={() => navigate(`/apps/mapping/${row.original.id}${window.location.search}`) }>
                {getValue()}
              </LinkStyles>
              <div>
                <StateStyle className={row.original.active ? "on": "off"}></StateStyle>
                <span>{row.original.active ? "On": "Off"}</span>
              </div>         
            </>
        },
      },
      {
        accessorFn: row => row.inSync,
        id: 'inSync',
        cell: info => info.getValue()?.toLocaleString("en") || "No Info",
        header: () => <span>In Sync</span>,
        meta: {
          filterVariant: 'range',
        },
      },
      {
        id: 'failing',
        accessorKey: 'failing',
        header: () => 'Failing',
        cell: info => info.getValue()?.toLocaleString("en") || "No Info",
        meta: {
          filterVariant: 'range',
        },
      },
      {
        id: 'lastEdited',
        accessorKey: 'lastEdited',
        header: () => <span>Last Edited</span>,
        cell: ({ row, getValue }) => {
          return <>
              <span> {formatDate(getValue().updatedAt)} </span>
              <div style={{fontSize: "12px"}}>
                {getValue().name} 
              </div>         
            </>
        },
        meta: {
          filterVariant: 'hidden',
        },
      },
    ],
    []
  )

  useEffect(() => {
    if(!generalApp || !account) return;
   
    searchObjectFetch({
      accountId: objQuery.accountId,
      appId: objQuery.appId,
      portalId: objQuery.portalId
    });
    let categoriesApp = generalApp.config.categoryObject;
    transactionRequest(categoriesApp);
  }, [generalApp, account])
  
  useEffect(() => {
    if(isSuccessObjects && isSuccessTransaction) {
      let categoriesApp = generalApp.config.categoryObject;

      let includesDataSync = []
      for (const item of responseObjects.data.objects) {
        let findConfigObject = categoriesApp[item.id];
        let findData = responseTransactions.data.filter(el => findConfigObject.includes(el.category));
        includesDataSync.push({
          ...item,
          inSync: findData.reduce((a, b) => a + Number(b.sync_count), 0),
          failing: findData.reduce((a, b) => a + Number(b.fail_count), 0),
        })
      }
      setData(includesDataSync);
    }
  }, [isSuccessObjects, isSuccessTransaction])
  
  const transactionRequest = async (categoriesApp) => {
    let categoriesList = [];
    for (const key in categoriesApp) {
      if (Object.hasOwnProperty.call(categoriesApp, key)) {
        const element = categoriesApp[key];
        categoriesList.push(...element)
      }
    }

    let idClientIntegrator = account.data.AppsSetting?.idClientIntegrador || account.data.VtexSetting?.idClientIntegrador || account.data.ShopifySetting?.id_client_integrador
    searchTransactions(
      {idClient: idClientIntegrator, dateStart: "2023-01-01", dateEnd: new Date().toJSON(),  categories: categoriesList},
    )
  }

  const handleSyncNow = () => {
    let categoriesApp = generalApp.config.categoryObject;
    transactionRequest(categoriesApp)
  }

  return (
   <>
    <h2 className='h2'>Object View</h2>
    <section>
      <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
        <Button className={"hubspot button--sm"} onPress={handleSyncNow}>Sync now</Button>
      </div>
      <div>
        <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={false} />
      </div>
    </section>
   </>
  );
};
