import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../../Components/Table/HubSpot/tableHubSpot';
import { capitalLetterFormatting } from '../../../../../Utils/String';
import { CheckboxStyles } from './styles';
import Dots from '../../../../../Assets/img/Actions/Dots.svg';
import { DropdownMenuList } from '../../../../../Components/Dropdown/menuList';
import { useGetMappingMutation } from '../../../../../Store/api/apps';
import { getObjectsQueryUrl } from '../../../../../Utils/QueryUrl';
import ReactSelect from 'react-select';
import { styleThemeHS } from '../../../../../Styles/SelectTheme';
import { AlertForm } from '../../../../../Utils/Alert';

const optionsDirection = [
  {
    label: '→',
    value: "app_hs",
  },
  {
    label: '←',
    value: "hs_app",
  },
  {
    label: '⇌',
    value: "bidirectional",
  },
]

export const MappingTable = ({review = false, typeObject, setMappingData, mappingData, selectOptionsApp = [], selectOptionsHS = [], setDirectionData, directionData}) => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [showFilter, setShowFilter] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState([]);
  let [searchMapping, { isLoading: isLoadingMapping, isSuccess: isSuccessMapping, data: responseMapping }] = useGetMappingMutation();
  let navigate = useNavigate();
  useEffect(() => {
    if(!review) {
      searchMapping({
        accountId: objQuery.accountId,
        portalId: objQuery.portalId,
          appId: objQuery.appId,
          object: typeObject
      })
    }
  }, [review])
  
  useEffect(() => {
    if(isSuccessMapping){
      let dataMapping = [];
      for (const property of responseMapping.data.mappingProperties) {
        dataMapping.push({
          ...property,
          temp: {
            app_property: {
              id: property.app_property.id,
              label: property.app_property.label,
            },
            hs_property: {
              id: property.hs_property.id,
              label: property.hs_property.label,
            },
            direction: property.direction
          }
        })
      }
      if(!review) {
        setMappingData(dataMapping);
        setDirectionData(responseMapping.data.direction)
      }

    }
  }, [isSuccessMapping])
  
  const columns = useMemo(
    () => [
      {
        id: 'status',
        accessorFn: row => row.active ? "true": "false",
        cell: ({ getValue }) => {
          return <div style={{marginLeft: "1rem"}}>
              {getValue() === "true" ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="#7fd1de" fillRule="evenodd" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-1.2a8.8 8.8 0 1 0 0-17.6a8.8 8.8 0 0 0 0 17.6m-1.172-6.242l5.809-5.808l.848.849l-5.95 5.95a1 1 0 0 1-1.414 0L7 12.426l.849-.849l2.98 2.98z"/></svg>
              : 
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 32 32"><path fill="lightgray" d="M6.837 23.749A11.95 11.95 0 0 1 4 16C4 9.373 9.373 4 16 4c2.954 0 5.658 1.067 7.749 2.837zm1.414 1.414L25.163 8.251A11.95 11.95 0 0 1 28 16c0 6.627-5.373 12-12 12a11.95 11.95 0 0 1-7.749-2.837M2 16c0 7.732 6.268 14 14 14s14-6.268 14-14S23.732 2 16 2S2 8.268 2 16"/></svg>}      
            </div>
        },
        options: [{id: "true", label: "Active"}, {id: "false", label: "Inactive"}],
        meta: {
          filterVariant: 'select',
        },
        size: 70
      },
      {
        accessorFn: row => row.app_property?.label,
        id: 'app_property',
        header: () => <span>Shopify</span>,
        cell({row, getValue})  {
          console.log({row})
          return  row.getCanExpand() &&  row.getIsExpanded() ? 
          <div className='select'>
          <ReactSelect
              options={selectOptionsApp} 
              theme={(theme) => styleThemeHS(theme)}
              value={{
                label: row.original.temp.app_property.label,
                value: row.original.temp.app_property.id,
              }}
              onChange={(e) => handleSelectApp(e, row, "app_property")}
              menuPlacement="top"
            />
          </div>
          :  getValue()
        },
      },
      {
        id: 'direction',
        accessorKey: 'direction',
        header: () => 'Direction',
        cell({ row, getValue }) {
            let validateDirection = row.original.temp.app_property.direction ;
            // si bidireccional las 3 opciones son validas
            // si hs_app o app_hs solo habilita una unica opcion
            let optionsValidate = [...optionsDirection];
            if(validateDirection === "hs_app") {
              optionsValidate = optionsValidate.filter(el => el.value === "hs_app")
            } else if(validateDirection === "app_hs") {
              optionsValidate = optionsValidate.filter(el => el.value === "app_hs")
            }

            return  row.getCanExpand() && row.getIsExpanded() ? <div className='select small'>
            <ReactSelect
              options={optionsValidate }
              theme={(theme) => styleThemeHS(theme)}
              onChange={(e) => handleSelectDirection(e, row)}
              value={ optionsDirection.find(el => el.value === row.original.temp.direction) || {}}
              menuPlacement="top"
              placeholder=""
            />
            </div> :<div style={{marginLeft: "1rem"}}>
                {getValue() === "app_hs" ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="M16.15 13H5q-.425 0-.712-.288T4 12t.288-.712T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.312t.712.287L19.3 11.3q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7z"/></svg>
                :
                getValue() === "hs_app"  ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12t-.288.713T19 13z"/></svg>
                :
                  <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="m5.825 17l1.9 1.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-3.6-3.6q-.15-.15-.213-.325T2.426 16t.063-.375t.212-.325l3.6-3.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L5.825 15H20q.425 0 .713.288T21 16t-.288.713T20 17zm12.35-8H4q-.425 0-.712-.288T3 8t.288-.712T4 7h14.175l-1.9-1.9q-.3-.3-.287-.7t.312-.7q.3-.275.7-.288t.7.288l3.6 3.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-3.6 3.6q-.275.275-.687.275T16.3 12.3q-.3-.3-.3-.712t.3-.713z"/></svg>
            } 
              </div>
          },
        options: [{id: "app_hs", label: "Shopify to HubSpot"}, {id: "hs_app", label: "HubSpot to Shopify"}, {id: "bidirecctional", label: "Bidireccional"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'hs_property',
        accessorFn: row => row.hs_property?.label,
        header: () => <span>HubSpot</span>,
        cell({row, getValue})  {
          return  row.getCanExpand() &&  row.getIsExpanded() ? 
          <div className='select'>
          <ReactSelect
              options={selectOptionsHS} 
              theme={(theme) => styleThemeHS(theme)}
              value={{
                label: row.original.temp.hs_property.label,
                value: row.original.temp.hs_property.id,
              }}
              onChange={(e) => handleSelectApp(e, row, "hs_property")}
              menuPlacement="top"
            />
          </div>
          :  getValue()
        },
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: () => <span>Mapping Type</span>,
        cell: info => capitalLetterFormatting(info.getValue()),
        options: [{id: "default", label: "Default"}, {id: "custom", label: "Custom"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'active',
        accessorKey: 'active',
        header: () => <span></span>,
        cell({ row, getValue }) {
            return row.getCanExpand() ? ( <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                  <div className='expand-section'>
                      { !row.getIsExpanded() ?
                        <span {...{
                          onClick: row.getToggleExpandedHandler(),
                        }}>
                          <Button className={"hubspot button--sm"} disabled={row.original.type.startsWith("default")}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="currentColor" d="m2.292 13.36l4.523 4.756L.5 20zM12.705 2.412l4.522 4.755L7.266 17.64l-4.523-4.754zM16.142.348l2.976 3.129c.807.848.086 1.613.086 1.613l-1.521 1.6l-4.524-4.757L14.68.334l.02-.019c.119-.112.776-.668 1.443.033"/></svg></span>
                            </div>
                          </Button>
                        </span> : <>
                        <div className='actions'>
                            <Button className={"hubspot button--sm"} bgColor={"#425b76"} color="white" bgColorHover={"#425b76"} onPress={() => handleUpdateCustomProperty(row)}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 36 36"><path fill="white" d="M13.72 27.69L3.29 17.27a1 1 0 0 1 1.41-1.41l9 9L31.29 7.29A1 1 0 0 1 32.7 8.7Z" className="clr-i-outline clr-i-outline-path-1"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                </div>
                            </Button>
                            <Button className={"hubspot button--sm"} onPress={() => handleCancelCustomProperty(row)}>
                                <div style={{display: "flex", alignItems: "center"}} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 56 56"><path fill="currentColor" d="M10.023 43.023c-.796.797-.82 2.157 0 2.954c.82.796 2.157.796 2.977 0l15-15l15 15c.797.796 2.156.82 2.977 0c.796-.82.796-2.157 0-2.954L30.953 28l15.024-15c.796-.797.82-2.156 0-2.953c-.844-.82-2.18-.82-2.977 0l-15 15l-15-15c-.82-.82-2.18-.844-2.977 0c-.796.82-.796 2.156 0 2.953l15 15Z"/></svg>
                                </div>
                            </Button>
                          </div>
                        </>
                      }
                      <Button className={"hubspot button--sm"} disabled={row.original.type.startsWith("default")} onPress={() => handleDeleteCustomProperty(row)}>
                          <div style={{display: "flex", alignItems: "center"}}>
                              <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/></svg></span>
                          </div>
                      </Button>
                      { 
                        row.original.type !== "default_required" && <CheckboxStyles>
                          <label class="switch">
                              <input type="checkbox" checked={getValue()} onClick={() => handleSwitchProperty(row)}/>
                              <span class="slider"></span>
                          </label>
                        </CheckboxStyles>
                      } 

                  </div>
                
            </div>) : ""
          },
        meta: {
            filterVariant: 'hidden',
          },
      },
    ],
    [mappingData, selectOptionsHS]
  )

  const handleUpdateCustomProperty = (row) => {
    if(row.original.temp.app_property && row.original.temp.direction && row.original.temp.hs_property){
      row.toggleExpanded();
      let index = row.index;
      let updateMapping = [...mappingData];
      let activeProperty;
      if(directionData !== "bidirectional" && row.original.temp.direction === "bidirectional") {
        activeProperty = true;       
      } else if (directionData === "hs_app" && row.original.temp.direction !== "hs_app"){
        activeProperty = false;
      } else if (directionData === "app_hs" && row.original.temp.direction !== "app_hs"){
        activeProperty = false;
      } else {
        activeProperty = true;
      }
      updateMapping[index] = {
        ...row.original,
        active: activeProperty,
        app_property: row.original.temp.app_property,
        direction: row.original.temp.direction,
        hs_property: row.original.temp.hs_property,
      }
      setMappingData(updateMapping)
    } else {
      AlertForm({title: "Por favor validar que los campos tangan información"})
    }
  }

  const handleCancelCustomProperty = (row) => {
    row.toggleExpanded()
  }

  const handleDeleteCustomProperty = (row) => {
    let index = row.index;
    let deleteMapping = [...mappingData]
    deleteMapping.splice(index, 1)
    setMappingData(deleteMapping);
  }

  const handleSelectApp = (e, row, prop) => {
    let index = row.index;
    
    let updateMapping = [...mappingData];
    updateMapping[index] = {
      ...row.original,
      temp: { 
        ...row.original.temp,
        [prop]:{
          label: e.label,
          id: e.value,
          direction: e.direction
        },
        direction: prop === "app_property" ? "": row.original.temp.direction

      }
    }
    setMappingData(updateMapping)
  }
  const handleSelectDirection = (e, row) => {
    let index = row.index;

    let updateMapping = [...mappingData];
    updateMapping[index] = {
      ...row.original,
      temp: { 
        ...row.original.temp,
        direction: e.value
      }
    }
    setMappingData(updateMapping)
  }

  const handleSwitchProperty = (row) => {
    //debugger;
    let index = row.index;
    let valueSwitch;
    let findProperty = selectOptionsApp.find(el => el.value === row.original.app_property.id)
    let direction = row.original.direction || "app_hs";
    if(row.original.type === "default_required") {
      valueSwitch = true;
    } else if(directionData === "bidirectional" || direction === "bidirectional" ||
     (direction === "hs_app" && directionData === "hs_app") || 
     (direction === "app_hs" && directionData === "app_hs") ) {
      valueSwitch = !row.original.active;
    } else if(directionData === "hs_app" && row.original.direction !== "hs_app"){
      valueSwitch = false;
      AlertForm({title: "No se puede activar propiedad", text: "No es posible la sincronización, la sincronización general esta en el otro sentido"})
    } else if(directionData === "app_hs" && row.original.direction !== "app_hs"){
      valueSwitch = false;
      AlertForm({title: "No se puede activar propiedad", text: "No es posible la sincronización, la sincronización general esta en el otro sentido"})
    } 
    let updateMapping = [...mappingData];
    updateMapping[index] = {
      ...row.original,
      active: valueSwitch
    }
    setMappingData(updateMapping)
  }

  return (
   <>
    <section>
      {!review && <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
      </div>}
      <div>
        <TableHubSpot dataTable={mappingData} showFilter={showFilter} columns={review ? columns.slice(0, -2) :columns} rowExpand={true} subComponent={RenderSubComponent} />
      </div>
    </section>
   </>
  );
};

const RenderSubComponent = ({row}) => {
  return <section>
  
  </section>

}